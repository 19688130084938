html {
  box-sizing: border-box;
  overflow-y: scroll;
  background-color: rgb(15 23 42);
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(241 245 249);
  box-sizing: border-box;
}

button {
  font-family: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  color: rgb(241 245 249);
  text-decoration: none;
}

svg {
  box-sizing: content-box;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background: #1e293b;
}

::-webkit-scrollbar-thumb {
  background-color: #475569;
  border: 3px solid #1e293b;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #64748b;
}
